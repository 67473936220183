<template>
  <div class="accountset">
    <userheader menucurrent=2 />
    <div class="main-div">
      <div class="account-content wrap">
        <!-- 左侧导航 -->
        <userleft currindex=9 />

        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="title">专业职称认证<em></em></h3>
              <div class="info-cnt">
                <div class="content-rb resetpwd">
                  <el-form :model="form" :label-position="labelPosition" :rules="rules" label-width="100px"
                    class="elform" ref="elForm">
                    <el-form-item label="职称类目">
                      <el-select placeholder="请选择职称类目" :disabled="isdisabled" style="width:140px" @change="oncerttypechange"
                        v-model="form.certtypepname">
                        <el-option v-for="item in options" :key="item.id" :label="item.data_title" :value="item.id">
                        </el-option>
                      </el-select>
                      <el-select placeholder="请选择职称类目"  :disabled="isdisabled" style="width:140px;margin-left:5px;"
                        @change="oncerttypechangesecond" v-model="form.certtypesecondname">
                        <el-option value="" label="">请选择</el-option>
                        <el-option v-for="item in optionssecond" :key="item.id" :label="item.data_title"
                          :value="item.id">
                        </el-option>
                      </el-select>
                      <el-select placeholder="请选择职称类目"  :disabled="isdisabled" style="width:140px;margin-left:5px;"
                        @change="oncerttypechangethird" v-model="form.certtypethirdname">
                        <el-option value="" label="">请选择</el-option>
                        <el-option v-for="item in optionsthird" :key="item.id" :label="item.data_title"
                          :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item class="formL" label="职称名称" prop="certtype">
                      <el-select placeholder="请选择职称类目"  :disabled="isdisabled" @change="oncerttypethreechange" v-model="form.certtype">
                        <el-option value="" label="">请选择</el-option>
                        <el-option v-for="item in certtypedatachildthree" :key="item.id" :label="item.data_title"
                          :value="item">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <!-- <el-form-item class="formL" label="姓名" v-if="userinfo!=null">
                      <span>{{ userinfo.iDcardName }}</span>
                    </el-form-item>
                    <el-form-item class="formL" label="性别" v-if="userinfo!=null">
                      <span>{{ userinfo.iDcardSex }}</span>
                    </el-form-item>
                    <el-form-item class="formL" label="证件号码" v-if="userinfo!=null">
                      <span>{{ userinfo.iDcardNO }}</span>
                    </el-form-item>
                    <el-form-item class="formL" label="出生年月" v-if="userinfo!=null">
                      <span>{{ userinfo.iDcardBirthdate }}</span>
                    </el-form-item> -->
                    <el-form-item label="批准日期" prop="approvaldate">
                      <el-col :span="11">
                        <el-date-picker type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期"
                          v-model="form.approvaldate" style="width: 100%;">
                        </el-date-picker>
                      </el-col>
                    </el-form-item>
                    <el-form-item class="formL" label="管理号" prop="ManagementNO1">
                      <el-input v-model="form.ManagementNO"></el-input>
                    </el-form-item>
                    <el-form-item label="资格证书" prop="certimg">
                      <el-upload class="" :headers="myHeaders" list-type="picture-card"
                        :action="uploadUrl" name="file" accept="image/png,image/gif,image/jpg,image/jpeg" 
                        :file-list="fileList" :on-success="busSuccess" :on-error="busError" :on-change="busChange"
                        :show-file-list="false">
                        <img v-if="form.certimgUrl!=''" style="width:100%;height:100%;" :src="form.certimgUrl"
                          class="avatar">
                        <i v-else class="el-icon-plus"></i>
                      </el-upload>
                    </el-form-item>
                    <el-form-item class="formL" v-if="isreg" label="注册号" prop="registerno">
                      <el-input v-model="form.registerno"></el-input>
                    </el-form-item>
                    <el-form-item class="formL" v-if="isreg" label="执业机构" prop="employer">
                      <el-select v-model="form.employer" filterable remote reserve-keyword placeholder="请输入关键词"
                        :remote-method="ongetcompanyquerylist" :loading="loading" @change="onemployerchange">
                        <el-option v-for="item in employerlist" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item class="formL" label="签发机关" prop="issuingauthority">
                      <el-input v-model="form.issuingauthority"></el-input>
                    </el-form-item>
                    <!-- <el-form-item class="" label="签发日期" prop="issuedate">
                      <el-col :span="11">
                        <el-date-picker type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期"
                          v-model="form.issuedate" style="width: 100%;">
                        </el-date-picker>
                      </el-col>
                    </el-form-item> -->
                    <el-form-item class="formL " v-if="isreg" label="有效期至" prop="expirydate">
                      <el-date-picker type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期"
                        v-model="form.expirydate" style="width: 164px;margin-right:10px">
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item label="注册证书" v-if="isreg" prop="CertRegistImg">
                      <el-upload class="" :headers="myHeaders" list-type="picture-card" :on-remove="handleRemovereg"
                        :action="uploadUrl" name="file" accept="image/png,image/gif,image/jpg,image/jpeg"
                        :file-list="fileListReg" :on-success="busSuccessreg" :on-error="busError"
                        :on-change="busChangereg" :show-file-list="false">
                        <img v-if="form.CertRegistImgUrl!=''" style="width:100%;height:100%;"
                          :src="form.CertRegistImgUrl" class="avatar">
                        <i v-else class="el-icon-plus"></i>
                      </el-upload>
                    </el-form-item>
                    <el-form-item>
                      <el-button class="btnsave" @click="onsubmitForm('elForm','save')">保存</el-button>
                      <el-button @click="onsubmitForm('elForm','submit')">提交</el-button>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <userfooter />
    <el-dialog :visible.sync="dialogimgVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import { usergetcurruserinfo } from '@/api/user';
import { getcompanyquerylist } from '@/api/company';
import { getCookieByName } from '@/utils/helper';
var token = getCookieByName('access_token');
import {
  getprofessioncertinfo,
  professiongetcerttype,
  professioncertauth,
  professiongetcerttypebypid,
} from '@/api/user';
import userleft from '@/components/userleft.vue';
export default {
  name: 'App',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
  },
  data() {
    const self = this;
    return {
      userinfo: null,
      isdisabled:false,
      options: [],
      optionssecond: [],
      optionsthird: [],
      active: 1,
      certtypedatachildthree: [],
      istoform: true,
      dialogImageUrl: '',
      businessend: '',
      labelPosition: 'right',
      firstShow: true,
      show: false,
      height: '600',
      dialogimgVisible: false,
      certtypedata: [],
      certtypedatachild: [],
      fileList: [],
      fileListReg: [],
      myHeaders: { Authorization: 'Bearer ' + token },
      uploadUrl: this.$uploadUrlN + '/utils/uploadfile',
      isauthresult: 1,
      form: {
        certtype: '',
        certtypeid: 0,
        registerno: '',
        employer: '',
        issuingauthority: '',
        approvaldate: '',
        issuedate: '',
        expirydate: '',
        certimg: '',
        certimgUrl: '',
        certtypechild: null,
        certtypechildid: '',
        certtypepid: '',
        certtypesecondid: '',
        certtypethirdid: '',
        certtypepname: '',
        certtypesecondname: '',
        certtypethirdname: '',
        CertRegistImg: '',
        CertRegistImgUrl: '',
        ManagementNO: '',
        EmployerCompanyId: 0,
      },
      rules: {
        certtype: [{ required: true, message: '请选择职称类目' }],
        registerno: [{ required: true, message: '请填写注册号' }],
        employer: [{ required: true, message: '请填写执业机构' }],
        issuingauthority: [{ required: true, message: '请填写签发单位' }],
        approvaldate: [{ required: true, message: '请填写批准日期' }],
        // issuedate: [{ required: true, message: '请填写签发日期' }],
        expirydate: [{ required: true, message: '请填写有效期至' }],
        certimg: [{ required: true, message: '请上传资格证书' }],
        CertRegistImg: [{ required: true, message: '请上传注册证书' }],
        ManagementNO: [{ required: true, message: '请上填写管理号' }],
      },
      isreg: false,
      employerlist: [],
      loading: false,
    };
  },
  mounted() {
    screenLeft;
    this.onusergetcurruserinfo();
    if(this.$route.query.id){
      this.form.id = this.$route.query.id
      this.isdisabled = true
      this.ongetprofessioncertinfo()
    }
  },
  methods: {
    ongetprofessioncertinfo(){
      getprofessioncertinfo({id:this.form.id}).then(res=>{
        if (res.data.code == 1) {
          this.form.certtypepname = res.data.result.certTypeList[0]
          this.form.certtypesecondname = res.data.result.certTypeList[1]
          this.form.certtypethirdname = res.data.result.certTypeList[2]
          this.form.certtype = res.data.result.certTypeList[3]
          this.form.certtypeid = res.data.result.certTypeId
          this.form.approvaldate = res.data.result.approvalDate
          this.form.ManagementNO = res.data.result.managementNO
          this.form.issuingauthority = res.data.result.issuingAuthority
          this.form.certimg = res.data.result.certImg
          this.form.certimgUrl = res.data.result.certImgOUrl
        }
      })
    },
    onemployerchange(item) {
      this.form.EmployerCompanyId = item;
    },
    ongetcompanyquerylist(query) {
      if (query !== '') {
        this.loading = true;
        let params = { nameKeywords: query };
        getcompanyquerylist(params).then((res) => {
          if (res.data.code == 1) {
            this.loading = false;
            this.employerlist = res.data.result;
            this.form.employer = query;
          }
        });
      }
    },
    onusergetcurruserinfo() {
      usergetcurruserinfo().then((res) => {
        if (res.data.code == 1) {
          this.userinfo = res.data.result;
        }
      });
    },
    oncerttypechange(item) {
      this.form.certtype = '';
      this.form.certtypeid = '';
      this.form.certtypepid = 0;

      let typename = '注册类';
      this.options.forEach((resu) => {
        if (resu.id == item) {
          typename = resu.data_title;
        }
      });
      if (typename == '注册类') {
        this.isreg = false;
      } else {
        this.isreg = false;
      }
      this.getcerttypechangesecond(item);
    },
    oncerttypechangesecond(item) {
      this.form.certtype = '';
      this.form.certtypeid = '';
      this.form.certtypesecondid = item.id;
      this.getcerttypechangethird(item);
    },
    oncerttypechangethird(item) {
      this.form.certtype = '';
      this.form.certtypeid = '';
      this.form.certtypethirdid = item.id;
      this.onprofessiongetcerttypebypidthree(item);
    },
    onsubmitForm(formName, type) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // var PhotoUrlArray = new Array();
          // this.fileList.forEach(function (item, index) {
          //   PhotoUrlArray.push(item.response.result.fileToken);
          // });
          // this.form.certimg = PhotoUrlArray.join(',');
          if(this.isdisabled){
            let param = {
              CertType:this.form.certtype,
              CertTypeId:this.form.certtypeid,
              ManagementNO:this.form.ManagementNO,
              IssuingAuthority:this.form.issuingauthority,
              ApprovalDate:this.form.approvaldate,
              CertImg:this.form.certimg,
              id:this.form.id,
            }
            professioncertauth(param).then((res) => {
              if (res.data.code === 1) {
                if (type == 'save') {
                  this.$message({
                    message: '恭喜你，保存成功',
                    type: 'success',
                  });
                } else {
                  this.$message({
                    message: '恭喜你，提交成功',
                    type: 'success',
                  });
                  setTimeout(function () {
                    window.history.go(-1);
                  }, 300);
                }
              } else {
                this.$message.error(res.data.message);
              }
            });
          }else{  
            professioncertauth(this.form).then((res) => {
              if (res.data.code === 1) {
                if (type == 'save') {
                  this.$message({
                    message: '恭喜你，保存成功',
                    type: 'success',
                  });
                } else {
                  this.$message({
                    message: '恭喜你，提交成功',
                    type: 'success',
                  });
                  setTimeout(function () {
                    window.location.href = '/user/authenticationlist';
                  }, 300);
                }
              } else {
                this.$message.error(res.data.message);
              }
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    onprofessiongetcerttype() {
      var that = this;

      that.onprofessiongetcerttypebypid(0);
      // professiongetcerttype().then((res) => {
      //   if (res.data.code === 1) {
      //     that.certtypedata = res.data.result;
      //   }
      // });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogimgVisible = true;
    },
    busSuccess(response, file, fileList) {
      var that = this;
      this.fileList = fileList;
      if (response.code == 1) {
        this.form.certimg = response.result.fileToken;
        this.form.certimgUrl = response.result.url + '/raw';
      }
    },
    busError(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传失败');
    },
    busChange(file, fileList) {
      console.log(fileList)
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]];
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleRemovereg(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreviewreg(file) {
      this.dialogImageUrl = file.url;
      this.dialogimgVisible = true;
    },
    busSuccessreg(response, file, fileList) {
      var that = this;
      this.fileListReg = fileList;
      if (response.code == 1) {
        this.form.CertRegistImg = response.result.fileToken;
        this.form.CertRegistImgUrl = response.result.url + '/raw';
      }
    },
    busErrorreg(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传失败');
    },
    busChangereg(file, fileList) {
      if (fileList.length > 0) {
        this.fileListReg = [fileList[fileList.length - 1]];
      }
    },

    locatefal() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    addlocation() {
      this.dialogVisible = true;
    },
    ontoform(item) {
      this.istoform = false;
      this.active = 2;
      this.onprofessiongetcerttypebypid(item.id);
    },
    onprofessiongetcerttypebypid(pid) {
      var that = this;
      let para = { pid: pid };
      professiongetcerttypebypid(para).then((res) => {
        if (res.data.code === 1) {
          this.options = res.data.result;
          // if (pid == 0) {
          //   this.form.certtypechild = [];
          //   if (res.data.result.length > 0) {
          //     this.form.certtypechild.push(res.data.result[0].id);
          //   }
          //   res.data.result.forEach((certtype) => {
          //     let tem = {};
          //     tem.value = certtype.id;
          //     tem.label = certtype.data_title;

          //     tem.children = [];
          //     professiongetcerttypebypid({ pid: tem.value }).then((resc) => {
          //       if (resc.data.code === 1) {
          //         if (resc.data.result.length > 0) {
          //           this.form.certtypechild.push(resc.data.result[0].id);
          //           this.onprofessiongetcerttypebypidthree(resc.data.result[0].id);
          //           //  this.form.certtypeid=resc.data.result[0].id;
          //           //  this.form.certtype=resc.data.result[0].data_title;
          //         }
          //         resc.data.result.forEach((certtypechild) => {
          //           let child = {};
          //           child.value = certtypechild.id;
          //           child.label = certtypechild.data_title;
          //           tem.children.push(child);
          //         });
          //       }
          //     });
          //     this.options.push(tem);

          //     //this.form.certtypechild=['评估','房产'];
          //     //console.log(this.form.certtypechild);
          //   });
          // }
        }
      });
    },
    getcerttypechangesecond(item) {
      var that = this;
      let para = { pid: item };
      professiongetcerttypebypid(para).then((res) => {
        if (res.data.code === 1) {
          that.optionssecond = res.data.result;
        }
      });
    },
    getcerttypechangethird(item) {
      var that = this;
      let para = { pid: item };
      professiongetcerttypebypid(para).then((res) => {
        if (res.data.code === 1) {
          that.optionsthird = res.data.result;
        }
      });
    },
    onprofessiongetcerttypebypidthree(item) {
      var that = this;
      let para = { pid: item };
      professiongetcerttypebypid(para).then((res) => {
        if (res.data.code === 1) {
          that.certtypedatachildthree = res.data.result;
        }
      });
    },
    oncerttypethreechange(item) {
      //console.log(item)
      this.form.certtypeid = item.id;
      this.form.certtype = item.data_title;
    },
  },
  created() {
    this.onprofessiongetcerttype();
  },
  
};
</script>
<style  scoped >
/**公共css开始**/
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.acount-card {
  background-position: 0 -172px;
  background-image: url('../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 940px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}

.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
  border: 1px solid #b3d8ff;
}
.accountset >>> form.elform {
  width: 540px;
}
/**公共css结束**/
.accountset >>> form.elform .formaddress .el-input {
  width: 220px;
  margin-right: 10px;
}
.accountset .info-cnt >>> .el-form-item__content button.showbusinessend {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  margin-left: 10px;
}
.accountset .info-cnt >>> .el-form-item__content button.chooseaddress {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}
.accountset >>> form.elform .small .el-form-item__label {
  line-height: 20px;
}
.accountset .account-nav li.cur i.detail {
  background-position: 0 -142px;
  background-image: url('../../assets/img/account-ico.png');
}

.accountset .stepone .el-row {
  width: 90%;
  margin: 0 auto;
}
.accountset .color-box {
  position: relative;
  border-radius: 4px;
  padding: 20px;
  margin: 5px 0;
  height: 114px;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  background: #7cbcff;
  box-shadow: 0 3px 8px 0 rgb(47 24 24 / 10%);
  transition: all 0.2s linear;
  cursor: pointer;
}
.accountset .color-box:hover {
  transform: translate3d(0, -2px, 0);
}
.accountset .bg-color-sub {
  width: 100%;
  height: 40px;
  left: 0;
  bottom: 0;
  position: absolute;
}
.accountset .stepline {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 50px;
}
.accountset .color-box .icon {
  font-size: 26px;
  text-align: center;
}
.accountset .bg-success-sub-item {
  width: 100%;
  height: 100%;
  display: inline-block;
  background: #ecf5ff;
  color: #5f5f5f;
  font-size: 16px;
  text-align: center;
  line-height: 35px;
}
.accountset .el-col {
  /* padding-left: 6px; */
  padding-right: 6px;
}
.accountset .info-cnt >>> .el-form-item__content button.btnsave {
  color: #409eff;
  background: #ecf5ff;
  border: 1px solid #b3d8ff;
  background-image: none;
}
</style>
